module.exports = [{
      plugin: require('../../../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-83944760-1","head":true},
    },{
      plugin: require('../../../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":700},
    },{
      plugin: require('../../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Jake Schwind","short_name":"Jake Schwind","start_url":"/","background_color":"#000000","theme_color":"#ffa500","display":"standalone","icon":"src/img/icon.png"},
    }]
